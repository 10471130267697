import { Party } from '../interfaces/game'

export const Cards: Party[] = [
  'liberal',
  'liberal',
  'liberal',
  'liberal',
  'liberal',
  'liberal',

  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
  'fascist',
]
