import { Scene } from './game'
import { SceneId } from '../../../helpers/id'

export const CAUSE_OF_DEATH: Scene = {
  id: SceneId(1),
  type: 'cause of death',
  title: 'Cause of Death',
  hints: [
    'Suffocation',
    'Severe Injury',
    'Loss of Blood',
    'Illness / Disease',
    'Poisoning',
    'Accident',
  ],
}

export const LOCATION_OF_CRIME: Scene[] = [
  {
    id: SceneId(2),
    type: 'location',
    title: 'Location of Crime',
    hints: [
      'Playground',
      'Classroom',
      'Dormitory',
      'Cafeteria',
      'Elevator',
      'Toilet',
    ],
  },
  {
    id: SceneId(3),
    type: 'location',
    title: 'Location of Crime',
    hints: [
      'Living Room',
      'Bedroom',
      'Storeroom',
      'Bathroom',
      'Kitchen',
      'Balcony',
    ],
  },
  {
    id: SceneId(4),
    type: 'location',
    title: 'Location of Crime',
    hints: [
      'Pub',
      'Bookstore',
      'Restaurant',
      'Hotel',
      'Hospital',
      'Building Site',
    ],
  },
  {
    id: SceneId(5),
    type: 'location',
    title: 'Location of Crime',
    hints: ['Vacation Home', 'Park', 'Supermarket', 'School', 'Woods', 'Bank'],
  },
]

export const SCENES: Scene[] = [
  {
    id: SceneId(6),
    type: 'misc',
    title: 'Hint on Corpse',
    hints: ['Head', 'Chest', 'Hand', 'Leg', 'Partial', 'All-over'],
  },
  {
    id: SceneId(7),
    type: 'misc',
    title: 'Corpse Condition',
    hints: [
      'Still Warm',
      'Stiff',
      'Decayed',
      'Incomplete',
      'Intact',
      'Twisted',
    ],
  },
  {
    id: SceneId(8),
    type: 'misc',
    title: 'Duration of Crime',
    hints: [
      'Instantaneous',
      'Brief',
      'Gradual',
      'Prolonged',
      'Few Days',
      'Unclear',
    ],
  },
  {
    id: SceneId(9),
    type: 'misc',
    title: 'Social Relationship',
    hints: [
      'Relatives',
      'Friends',
      'Colleagues',
      'Employer / Employee',
      'Lovers',
      'Strangers',
    ],
  },
  {
    id: SceneId(10),
    type: 'misc',
    title: 'Trace at the Scene',
    hints: [
      'Fingerprint',
      'Footprint',
      'Bruise',
      'Blood Stain',
      'Bodily Fluid',
      'Scar',
    ],
  },
  {
    id: SceneId(11),
    type: 'misc',
    title: 'Day of Crime',
    hints: ['Weekday', 'Weekend', 'Spring', 'Summer', 'Autumn', 'Winter'],
  },
  {
    id: SceneId(12),
    type: 'misc',
    title: 'In Progress',
    hints: [
      'Entertainment',
      'Relaxation',
      'Assembly',
      'Trading',
      'Visit',
      'Dining',
    ],
  },
  {
    id: SceneId(13),
    type: 'misc',
    title: 'Motive of Crime',
    hints: ['Hatred', 'Power', 'Money', 'Love', 'Jealousy', 'Justice'],
  },
  {
    id: SceneId(14),
    type: 'misc',
    title: `Victim's Identity`,
    hints: ['Child', 'Young Adult', 'Middle-Aged', 'Senior', 'Male', 'Female'],
  },
  {
    id: SceneId(15),
    type: 'misc',
    title: `General Impression`,
    hints: ['Common', 'Creative', 'Fishy', 'Cruel', 'Horrible', 'Suspenseful'],
  },
  {
    id: SceneId(16),
    type: 'misc',
    title: `Victim's Clothes`,
    hints: ['Neat', 'Untidy', 'Elegant', 'Shabby', 'Bizarre', 'Naked'],
  },
  {
    id: SceneId(17),
    type: 'misc',
    title: `State of the Scene`,
    hints: [
      'Bits and Pieces',
      'Ashes',
      'Water Stain',
      'Cracked',
      'Disorderly',
      'Tidy',
    ],
  },
  {
    id: SceneId(18),
    type: 'misc',
    title: `Victim's Expression`,
    hints: [
      'Peaceful',
      'Struggling',
      'Frightened',
      'In Pain',
      'Blank',
      'Angry',
    ],
  },
  {
    id: SceneId(19),
    type: 'misc',
    title: `Murderer's Personality`,
    hints: [
      'Arrogant',
      'Despicable',
      'Furious',
      'Greedy',
      'Forceful',
      'Perverted',
    ],
  },
  {
    id: SceneId(20),
    type: 'misc',
    title: `Time of Death`,
    hints: ['Dawn', 'Morning', 'Noon', 'Afternoon', 'Evening', 'Night'],
  },
  {
    id: SceneId(21),
    type: 'misc',
    title: `Evidence Left Behind`,
    hints: [
      'Natural',
      'Artistic',
      'Written',
      'Synthetic',
      'Personal',
      'Unrelated',
    ],
  },
  {
    id: SceneId(22),
    type: 'misc',
    title: `Noticed by Bystander`,
    hints: [
      'Sudden Sound',
      'Prolonged Sound',
      'Smell',
      'Visual',
      'Action',
      'Nothing',
    ],
  },
  {
    id: SceneId(23),
    type: 'misc',
    title: `Vitim's Build`,
    hints: ['Large', 'Thin', 'Tall', 'Short', 'Disfigured', 'Fit'],
  },
  {
    id: SceneId(24),
    type: 'misc',
    title: `Victim's Occupation`,
    hints: [
      'Boss',
      'Professional',
      'Worker',
      'Student',
      'Unemployed',
      'Retired',
    ],
  },
  {
    id: SceneId(25),
    type: 'misc',
    title: `Weather`,
    hints: ['Sunny', 'Stormy', 'Dry', 'Humid', 'Cold', 'Hot'],
  },
  {
    id: SceneId(26),
    type: 'misc',
    title: `Sudden Incident`,
    hints: [
      'Power Failure',
      'Fire',
      'Conflict',
      'Loss of Valuables',
      'Scream',
      'Nothing',
    ],
  },
]
